/* app.css */
/* Global Box Sizing and Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-image: linear-gradient(to bottom, #1e1e2e, #181824);
  color: #e4e4e7;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  padding: 0;
  max-width: 1200px;
  margin: auto;
}

/* Sticky Header Styling */
.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-image: linear-gradient(to bottom, #2c2d40, #222336);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.header-logo {
  height: 40px;
  width: auto;
}

.header-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Social Icons */
.social-icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.social-icon:hover {
  opacity: 1;
  cursor: pointer;
}

/* Buy Suifox Button */
.buy-suifox-button {
  background-color: transparent;
  color: #c2185b;
  border: 2px solid #c2185b;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
  margin-left: 5px;
  text-decoration: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-suifox-button:hover {
  background-color: #c2185b;
  color: #1e1e2e;
  border-color: #c2185b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Notification Bar */
.notification-bar {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-image: linear-gradient(to bottom, #2d3346, #2c3242, #2d3346);
  color: #4fdcdc;
  font-weight: bold;
  font-size: 14px;
  position: sticky;
  top: 60px;
  z-index: 998;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(79, 220, 220, 0.6);
  border-top: 1px solid rgba(79, 220, 220, 0.6);
}

.notification-text {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
}

.notification-text-content {
  display: inline-block;
  white-space: nowrap;
  animation: scrollText 30s linear infinite;
}

.white-text {
  color: #ffffff;
}

.red-text {
  color: #c2185b;
}

/* Close Notification Button */
.close-notification {
  background-color: #4fdcdc;
  color: #1e1e2e;
  border: 2px solid #4fdcdc;
  border-radius: 5px;
  padding: 0px 4px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  box-sizing: border-box;
  margin-left: 10px;
  flex-shrink: 0;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-notification:hover {
  background-color: transparent;
  color: #4fdcdc;
  opacity: 1;
  cursor: pointer;
}

/* Full Width Wrapper */
.full-width-wrapper {
  width: 100%;
  overflow-x: hidden;
}

/* Main Content Styling */
.App-main {
  padding: 0 25px 20px;
  padding-top: 0;
}

h1,
h2 {
  color: #f4f4f9;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  font-size: 32px;
}

/* Loading Container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.loading-spinner {
  width: 100px;
  height: 100px;
}

/* Pool Card */
.pool-card {
  margin-bottom: 10px;
  background-image: linear-gradient(to bottom, #2c2d40, #222336);
  border-radius: 8px;
  padding: 10px 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  border: 1px solid transparent;
  cursor: pointer;
}

.pool-card:hover,
.pool-card.pinned {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  border-color: teal;
}

/* Flash animations */
.pool-card.flash-buy {
  animation: flash-buy 1s forwards;
}

.pool-card.flash-sell {
  animation: flash-sell 1s forwards;
}

@keyframes flash-buy {
  0% {
    box-shadow: 0 0 20px rgba(46, 204, 113, 0.7);
  }
  100% {
    box-shadow: none;
  }
}

@keyframes flash-sell {
  0% {
    box-shadow: 0 0 20px rgba(231, 76, 60, 0.7);
  }
  100% {
    box-shadow: none;
  }
}

/* Pool Card Row */
.pool-card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.pool-card-row > * {
  flex-shrink: 1;
  min-width: 0;
  margin-bottom: 5px;
}

.pool-card-row .rank-display {
  flex: 0 0 50px;
}

/* Rank Display */
.rank-display {
  font-size: 16px;
  font-weight: bold;
  color: teal;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  margin-right: 10px;
}

.pool-card:hover .rank-display,
.rank-display.visible {
  opacity: 1;
}

/* Token Image and Name */
.token-image-name {
  display: flex;
  align-items: center;
  flex: 0 0 20px;
  min-width: 150px;
}

.token-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  max-width: 100%;
}

.token-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-width: 0;
  justify-content: center;
}

.token-name {
  font-size: 14px;
  color: #f4f4f9;
  font-weight: bold;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}

.token-symbol {
  font-size: 12px;
  color: #b0b0b5;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}

/* Token Stats */
.token-stats {
  flex: 1 1 auto;
  min-width: 0;
}

/* Token Stats Row */
.token-stats-row {
  display: flex;
  gap: 0px;
  width: 100%;
  margin-bottom: 5px;
  align-items: center;
}

.token-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  max-width: 100%;
  justify-content: flex-start;
  flex: 1;
}

.token-header {
  font-weight: bold;
  color: #3cb6b6;
  font-size: 12px;
  margin-bottom: 2px;
  text-align: center;
}

.token-stat div {
  text-align: center;
}

/* Positive and Negative Change */
.positive-change {
  color: #2ecc71;
  font-weight: bold;
}

.negative-change {
  color: #e74c3c;
  font-weight: bold;
}

/* Buttons Container */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 0 0 45px;
}

/* Section Button */
.section-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-button:hover {
  cursor: pointer;
}

.section-button:link,
.section-button:visited {
  text-decoration: none;
}

/* Movepump Logo */
.movepump-logo {
  width: 30px;
  height: 30px;
  opacity: 0.5;
    transition: color 0.3s ease, opacity 0.3s ease;
}

.movepump-logo:hover {
    opacity: 1;
}

/* Pin Icon Styling */
.pin-icon {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-left: 10px;
  flex: 0 0 20px;
}

.pool-card:hover .pin-icon,
.pin-icon.visible {
  opacity: 1;
}

.pin-image {
  width: 16px;
  height: 16px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  filter: invert(66%) sepia(58%) saturate(346%) hue-rotate(133deg)
    brightness(92%) contrast(92%);
}

.pin-image:hover,
.pin-image.pinned {
  opacity: 1;
}

/* Progress Bar */
.progress-bar-container {
  width: 100%;
  background-color: #44475a;
  border-radius: 10px;
  overflow: hidden;
  height: 20px;
  position: relative;
}

.progress-dynamic {
  height: 100%;
  background-color: #2ecc71;
  transition: width 0.4s ease;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: #ffffff;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Copy Button Styling */
.copy-button {
  background-color: transparent;
  border: 1px solid #008080;
  border-radius: 4px;
  color: #f4f4f9;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s, color 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: clamp(45px, 7vw, 60px);
}

.copy-button:hover {
  background-color: #00b3b3;
  color: #1e1e2e;
  cursor: pointer;
}

/* Chart Container */
.chart-container {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

.chart-container-modal {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
}

/* Search and Sort */
.search-and-sort {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.search-input,
.sort-select,
.additional-options {
  flex: 1;
  min-width: 0;
}

.search-input,
.sort-select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6272a4;
  background-color: #2c2d40;
  color: #f4f4f9;
  font-size: 14px;
  box-sizing: border-box;
}

.sort-select {
  text-align-last: center;
}

/* Additional Options */
.additional-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.filters-button,
.premium-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid transparent;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  min-width: 80px;
}

.filters-button {
  background-color: #3cb6b6;
  color: #1e1e2e;
  border-color: #3cb6b6;
}

.premium-button {
  background-color: #c2185b;
  color: #1e1e2e;
  border-color: #c2185b;
}

.filters-button:hover,
.premium-button:hover {
  opacity: 0.75;
  cursor: pointer;
}

.logo-between-buttons {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin: 0 10px;
}

/* Tooltip Styling */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #2c2d40;
  color: #f4f4f9;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.75;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 0.75;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.pagination-controls button {
  background-color: transparent;
  color: #3cb6b6;
  border: 2px solid #3cb6b6;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 5px 3px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-controls button:hover,
.pagination-controls button.active {
  background-color: #3cb6b6;
  color: #1e1e2e;
  border-color: #3cb6b6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
  .App-main {
    padding: 0 20px 20px;
    padding-top: 0;
  }

  .pool-card-row {
    flex-wrap: nowrap;
    padding: 0;
  }

  .pool-card-row > * {
    margin: 5px 2px;
  }

  .rank-display {
    font-size: 12px;
    flex: 0 0 40px;
    margin-right: -10px;
  }

  .token-image-name {
    flex: 1 1 10px;
    max-width: 100px;
    min-width: 80px;
  }

  .token-info {
    margin-left: 5px;
  }

  .token-name,
  .token-symbol {
    font-size: 12px;
    max-width: 80px;
  }

  .token-stats-row {
    flex: 1 1 120px;
    margin: 5px;
    display: flex;
  }

  .section-button {
    padding: 4px 6px;
    font-size: 11px;
    height: 35px;
  }

  .button-container {
    gap: 10px;
  }

  .pin-icon {
    flex: 0 0 10px;
    margin-left: 5px;
  }

  .pool-card {
    padding: 5px 10px;
  }

  .social-icon {
    width: 30px;
    height: 30px;
  }

  .buy-suifox-button {
    padding: 8px 12px;
    font-size: 14px;
    width: auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .token-image {
    width: 30px;
    height: 30px;
  }

  .sticky-header {
    flex-wrap: nowrap;
    padding: 10px;
    height: auto;
  }

  .header-logo {
    margin-bottom: 0;
  }

  .header-buttons {
    padding-right: 0;
    gap: 10px;
  }

  .search-and-sort {
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
  }

  .search-input,
  .sort-select {
    width: 100%;
    margin-bottom: 5px;
  }

  .additional-options {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    order: 3;
    width: 100%;
    margin-bottom: 5px;
  }

  .filters-button,
  .premium-button {
    flex: 1;
    font-size: 12px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
    height: 35px;
  }

  .filters-button {
    text-align: left;
  }

  .premium-button {
    text-align: right;
  }

  .logo-between-buttons {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    flex-shrink: 0;
  }

  .copy-button {
    width: clamp(40px, 7vw, 60px);
    font-size: 10px;
  }

  .notification-text {
    overflow: hidden;
    position: relative;
  }

  .notification-text-content {
    display: inline-block;
    white-space: nowrap;
    animation: scrollText 30s linear infinite;
  }

  .modal-overlay {
    overflow-x: hidden;
  }

  .modal-content {
    width: 95vw;
    max-width: none;
    height: auto;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
  }

  .modal-token-header {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 15px;
  }

  .modal-rank-display {
    font-size: 14px;
    margin-right: 10px;
  }

  .modal-token-image-name {
    flex: 1 1 auto;
    justify-content: center;
    margin-bottom: 0;
  }

  .modal-token-info-text .token-name {
    font-size: 14px;
  }

  .modal-token-info-text .token-symbol {
    font-size: 12px;
  }

  .modal-token-stats {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    margin-bottom: 20px;
  }

  .modal-token-stats .token-stat {
    flex: 1 1 45%;
    text-align: center;
    font-size: 12px;
    max-width: 100%;
  }

  .chart-container-modal {
    height: 200px;
  }

  table {
    table-layout: fixed;
  }

  th,
  td {
    font-size: 10px;
    padding: 6px 5px;
  }

  .copy-button {
    font-size: 10px;
    padding: 4px 5px;
  }

  .modal-overlay {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    margin: 0;
  }
}

/* Media Query for Screens Less Than 500px */
@media (max-width: 500px) {
  .App-main {
    padding: 0 10px 20px;
    padding-top: 0;
  }

  .search-and-sort {
    margin-top: 0px;
  }

  .notification-bar {
    top: 50px;
    padding: 5px 10px;
  }

  .pool-card {
    padding: 5px 5px;
  }

  .pool-card-row > * {
    margin: 5px 1px;
  }

  .section-button {
    padding: 3px 5px;
    font-size: 11px;
    height: 30px;
  }

  .pagination-controls button {
    padding: 4px 6px;
    font-size: 10px;
    margin: 4px 2px;
  }

  .footer {
    padding: 10px 0;
    font-size: 12px;
    text-align: center;
  }

  .footer-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: start;
    width: 100%;
    text-align: center;
  }

  .footer-left,
  .footer-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .footer-right {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    justify-items: center;
    width: 100%;
    padding: 0;
    list-style: none;
    margin: 10px 0 0 0;
  }

  .footer-links li {
    margin: 0;
  }

  .footer-links a {
    font-size: 12px;
    padding: 2px 0;
    width: 100%;
    max-width: 150px;
    text-align: center;
  }

  .token-image-name {
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    justify-content: center;
    margin-bottom: 10px;
  }

  .token-info {
    margin-left: 0;
    margin-top: 5px;
    text-align: center;
  }

  .token-image {
    margin-right: 0;
    margin-bottom: 5px;
    width: 30px;
    height: 30px;
  }

  .token-name,
  .token-symbol {
    font-size: 12px;
    max-width: 80px;
    text-align: center;
  }

  .pool-card-row .rank-display {
    flex: 0 0 15px;
  }

  .modal-token-stats {
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    margin-bottom: 20px;
  }

  .modal-token-stats .token-stat {
    flex: 1 1 45%;
    text-align: center;
    font-size: 12px;
    max-width: 100%;
  }

  .sticky-header {
    height: 50px;
    padding: 0 10px;
  }

  .header-logo {
    height: 30px;
  }

  .header-buttons {
    gap: 10px;
  }

  .table-container {
    overflow-x: auto;
    padding: 0 0px;
  }

  table {
    width: 100%;
    font-size: 10px;
  }

  th,
  td {
    padding: 6px 5px;
  }

  .modal-token-info-text .token-name {
    font-size: 14px;
  }

  .modal-token-info-text .token-symbol {
    font-size: 12px;
  }
}

/* Keyframes for Notification Text Scroll */
@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Analytics Header */
.analytics-header {
  font-size: 28px;
  font-weight: 800;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  color: #e4e4e7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1.3;
  position: relative;
}

.analytics-header span {
  color: #4fdcdc;
}

.analytics-header::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background-color: #4fdcdc;
  border-radius: 4px;
  box-shadow: 0 6px 4px -4px rgba(79, 220, 220, 0.3),
    0 10px 8px -8px rgba(36, 36, 48, 0.6);
}

/* Beta Header */
.beta-header {
  font-size: 18px;
  font-weight: bold;
  color: #a0aec0;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

/* Footer */
.footer {
  padding: 20px 0;
  color: rgba(228, 228, 231, 0.5);
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
}

.footer-left p {
  margin: 5px 0;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.footer-left p:hover {
  opacity: 1;
}

.footer-left a {
  color: #4fdcdc;
  text-decoration: none;
  opacity: 0.7;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.footer-left a:hover {
  color: #4fdcdc;
  opacity: 1;
  text-decoration: underline;
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.footer-links li {
  margin: 5px 15px;
}

.footer-links a {
  color: rgba(228, 228, 231, 0.5);
  text-decoration: none;
  font-size: 14px;
  opacity: 0.5;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.footer-links a:hover {
  color: #4fdcdc;
  opacity: 1;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    rgba(30, 30, 46, 0.95),
    rgba(20, 20, 36, 0.95)
  );
  backdrop-filter: blur(5px);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;
}

.modal-content {
  background-image: linear-gradient(to bottom, #2c2d40, #222336);
  border-radius: 8px;
  padding: 20px;
  width: 95vw;
  max-width: none;
  height: auto;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  margin: 0;
  max-width: 600px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: #e4e4e7;
  border: none;
  font-size: 30px;
  cursor: pointer;
  line-height: 1;
}

.modal-close-button:hover {
  color: #4fdcdc;
  cursor: pointer;
}

.modal-token-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-token-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.modal-rank-display {
  font-size: 16px;
  font-weight: bold;
  color: teal;
  margin-right: 15px;
  opacity: 1;
}

.modal-token-image-name {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  margin-bottom: 0;
}

.token-image-modal {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  flex-shrink: 0;
  max-width: 100%;
}

.modal-token-info-text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-width: 0;
  justify-content: center;
}

.modal-token-info-text .token-name {
  font-size: 16px;
  color: #f4f4f9;
  font-weight: bold;
  margin-bottom: 2px;
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}

.modal-token-info-text .token-symbol {
  font-size: 14px;
  color: #b0b0b5;
  margin-bottom: 0;
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}

/* Modal Token Stats */
.modal-token-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.modal-token-stats .token-stat {
  text-align: center;
  font-size: 12px;
  max-width: 100%;
  flex: 1 1 30%;
}

/* Progress Bar in Modal */
.progress-bar-container {
  margin-top: 5px;
  margin-bottom: 4px;
}

/* Table Styling */
.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

th,
td {
  padding: 12px 15px;
  text-align: center;
  color: #e4e4e7;
  border-bottom: 1px solid #434466;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  background-color: #44475a;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
}

td {
  background-color: #2c2d40;
  vertical-align: middle;
}

.buy-text {
  color: #2ecc71;
  font-weight: bold;
}

.sell-text {
  color: #e74c3c;
  font-weight: bold;
}

/* Modal Pagination Controls */
.pagination-controls .modal-page-info {
  margin: 0 10px;
  color: #e4e4e7;
  font-size: 14px;
  display: flex;
  align-items: center;
}
